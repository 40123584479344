<script>
export default {
  name: 'editProfile',
  data () {
    return {
      showError: false,
      menu: false,
      date: this.$store.state.User.profile.info.dateNaissance ? this.$store.state.User.profile.info.dateNaissance : null,
      prenom: this.$store.state.User.profile.info.prenom ? this.$store.state.User.profile.info.prenom : null,
      nom: this.$store.state.User.profile.info.nom ? this.$store.state.User.profile.info.nom : null
    }
  },
  components: {
  },
  computed: {
    validatedForm () {
      if (this.date && this.prenom && this.nom) {
        return false
      } else {
        return true
      }
    },
    showProfilEdition: {
      get () { return this.$store.state.Dialogs.showProfilEdition },
      set (val) { this.$store.commit('Dialogs/setProfilEdition', val) }
    }
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    reset () {
    },
    clearDialog () {
      this.showError = false
      this.showProfilEdition = false
    },
    save (date) {
      // this.$emit('select', this.date)
      this.$refs.menu.save(date)
    },
    async saveProfil () {
      try {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/updateSalleClasseUsager.gql'),
          client: 'v2',
          variables: {
            info: {
              prenom: this.prenom,
              nom: this.nom,
              dateNaissance: this.date
            }
          }
        }).then((data) => {
          this.$store.commit('User/setRefetchUserInfo', true)
          this.clearDialog()
        })
      } catch (e) {
        this.loadingFinish = false
        this.showError = true
        /* eslint-disable-next-line */
        console.error(e)
      }
    }
  }
}
</script>

<template lang='pug'>
div
  v-dialog(v-model='showProfilEdition', max-width='600', content-class='custom-dialog', persistent, light)
    v-card.light()
      v-hover(v-slot:default="{ hover }")
        v-btn.dialog-button(@click='clearDialog', @click.native='reset', depressed, fab, x-large, color='secondary')
          font-awesome-icon.f3(:icon="['fas', 'times']")
      v-card-title.dialog-title Modifier votre profil

      v-card-text.relative
        v-form(ref='profilUpdate')
          div
            label Prénom
            v-text-field(placeholder='Le nom de ta liste ici!', solo, flat, outlined, hide-details, v-model='prenom', clearable, @keyup.enter='createList')
          div.mt2
            label Nom
            v-text-field(placeholder='Le nom de ta liste ici!', solo, flat, outlined, hide-details, v-model='nom', clearable, @keyup.enter='createList')
          div.mt2.mb4
            label Date de naissance
            v-menu(ref='menu', v-model='menu', :close-on-content-click='false', transition='scale-transition', offset-y, min-width='290px', color='primary', content-class='date-menu')
              template(v-slot:activator='{ on }')
                v-text-field(v-model='date', label='Date de naissance', v-on='on', color='secondary', readonly, solo, flat, outlined, hide-details)
              v-date-picker(ref='picker', v-model='date', :max='new Date().toISOString().substr(0, 10)', locale='fr-CA', min='1950-01-01', color='primary', @change='save')

      v-btn(@click='saveProfil', color='secondary', depressed, block, x-large, dark, :disabled='validatedForm')
        span.f4 Envoyer
  v-dialog(v-model='showError', max-width='500', content-class='custom-dialog', persistent)
    v-card.error()
      v-card-title.dialog-title.white--text Échec de la modification
      v-card-text.white--text
        div.f5.mb3.tc Une erreur est survenue lors de la mise à jour du profil. Réessayez plus tard.
        //- v-divider.primary
      v-card-actions.secondary
        v-spacer
        v-btn(@click='clearDialog', @click.native='reset', outlined, color='light') Fermer
</template>
<style lang='sass'>

</style>
