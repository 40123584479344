/*
import calendrierCalendrierElements from '@/mixins/calendrierCalendrierElements.js'
  mixins: [calendrierCalendrierElements],
*/

export default {
  methods: {
    async countElementsUsagersForCalendrierCalendrierElement (cceIdentifiant) {
      if (!cceIdentifiant) {
        return null
      }

      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/minimalCalendrierCalendrierElements.gql'),
        variables: {
          filtre: {
            identifiants: [cceIdentifiant]
          },
          batch: true
        }
      })
      const cces = data?.calendrierCalendrierElements?.resultat
      return this.getElementsUsagersFromCceList(cces)
    },
    getElementsUsagersFromCceList (cces) {
      return cces?.reduce((prev, curr) => (curr?.elementsUsagers?.total ?? 0) + prev, 0) ?? 0
    }
  }
}
