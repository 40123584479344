<script>
import ChatControls from '@/mixins/chatControls.js'

export default {
  name: 'DevoirPreview',
  mixins: [ChatControls],
  props: {
    devoir: { required: true, type: Object }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    devoirActif () {
      if (this.devoir.finAt && this.devoir.debutAt) {
        const dateDujour = new Date()
        const dateToCompare = new Date(this.devoir.debutAt)
        const dateToCompareFin = new Date(this.devoir.finAt)
        const plushaut = dateDujour.getTime() > dateToCompare.getTime()
        const plusbas = dateDujour.getTime() < dateToCompareFin.getTime()
        if (plushaut && plusbas) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  }
}
</script>

<template lang='pug'>
div.devoir-preview()
  template(v-if='loading')
    v-list-item.eleve-card__main-content(depressed, flat)
      v-skeleton-loader.w-100(type="list-item-avatar-three-line")
  template(v-else)
    v-list-item
      v-list-item-avatar(size='30')
        img(src='@/assets/images/logos/logo-classe-maison.svg', width='20')

      v-list-item-content
        //- v-list-item-subtitle
          v-chip(outlined, x-small, style='padding: 2px 5px')
            div.success.mr1(style='width:10px; height: 10px; border-radius: 10px')
            | Devoir actif
        v-list-item-title {{devoir.titre}}
        v-list-item-subtitle.flex.items-center.mt1
          v-chip(v-if='devoir.finAt', outlined, x-small, style='padding: 2px 5px')
            font-awesome-icon.mr2(:icon="['fad', 'calendar-alt']")
            span Échéance le {{ new Date(devoir.finAt).toLocaleDateString('fr-CA', { month: 'long', day: 'numeric' }) }}
          v-chip.ml1(outlined, x-small, style='padding: 2px 5px')
            div.success.mr1(v-if='devoir.status.toLowerCase() === "actif" || devoir.status.toLowerCase() === "en_cours" || devoir.status.toLowerCase() === "approuvé"', style='width:10px; height: 10px; border-radius: 10px')
            | Devoir {{devoir.status}}
          v-chip.ml1(outlined, x-small, style='padding: 2px 5px')
            font-awesome-icon.mr2(:icon="['fad', 'calendar-alt']")
            span {{devoir.exercices.length}} Exercices
          //- v-chip.ml1(outlined, x-small, style='padding: 2px 5px')
            font-awesome-icon.mr2(:icon="['fad', 'calendar-alt']")
            span {{devoir.nombreUsagers}} Élèves
      div.actions__wrapper
        v-btn(fab, depressed, color='secondary', small, to="/devoir-hardcodded")
          font-awesome-icon(:icon="['far', 'arrow-right']")
  //- template
    v-list-item.eleve-card__main-content.animated.fadeIn(depressed, flat)
      v-list-item-avatar.mr3(size='35', style='margin-right:10px', color='primary', v-if='!$store.state.App.mobileTemplate')
        img(v-if='user && user.individu & user.individu.avatar && user.individu.avatar.image && user.individu.avatar.image.url_to_image', :src='user.individu.avatar.image.url_to_image.includes("svg") ? user.individu.avatar.image.url_to_image : "https://plus.dokoma.com" + user.individu.avatar.image.url_to_image')
        div.white--text.f7.ttu(v-else-if='user.prenom && user.nom') {{user.prenom[0]}}.{{user.nom[0]}}.
      v-list-item-content
        v-list-item-subtitle.o-80(v-if='user.last_sign_in_at') Dernière connexion: {{formatDate(user.last_sign_in_at.split(' ')[0])}}
        v-list-item-title.card-title(v-if='user.prenom && user.nom') {{user.prenom}} {{user.nom}}
        v-list-item-subtitle.black--text.i(v-if='user.username') @{{user.username}}
      div.actions__wrapper
        v-menu(offset-y)
          template(v-slot:activator='{ on }')
            v-btn.mr2(icon,small, @click.prevent.stop='', v-on='on')
              font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
          v-card.pv2
            v-list(dense, nav)
              v-list-item(@click='')
                font-awesome-icon.f6.mr2(:icon="['fad', 'wave-sine']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Voir les résultats
              v-list-item(@click='connectToEnfant')
                font-awesome-icon.f6.mr2(:icon="['fad', 'graduation-cap']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Accéder au compte élève
              v-list-item(@click='goChat')
                font-awesome-icon.f6.mr2(:icon="['fad', 'comment-smile']", style='min-width: 24px')
                v-list-item-content
                  v-list-item-title Envoyer un message
              v-divider.mv2
              v-list-group(@click.stop='', color='secondary')
                template(v-slot:activator='')
                  v-list-item-content
                    v-list-item-title Actions
                template()
                  v-list-item(@click='')
                    v-list-item-title Ajouter à un groupe
                  v-list-item(@click='')
                    v-list-item-title Supprimer
        v-btn(fab, depressed, color='secondary', small, :to="{ path: 'enfants/' + user.id }")
          font-awesome-icon(:icon="['far', 'arrow-right']")
</template>
<style lang='sass' scoped>
@import 'src/styles/components/presentation/_elevePreview'

</style>
