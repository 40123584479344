export default {
  computed: {
    devoirs () {
      let tabDeRef = null
      if (this.devoirRecents) {
        tabDeRef = this.devoirRecents
      } else if (this.devoirsActifs) {
        tabDeRef = this.devoirsActifs
      }
      if (tabDeRef && tabDeRef.length > 0) {
        const temp = []
        tabDeRef.forEach((devoir) => {
          if (!temp.find(e => e.identifiant === devoir.identifiant)) {
            if (this.$store.state.Exercices.periodeActive && (devoir.debutAt || devoir.finAt)) {
              let dateDeb = null
              let dateFin = null
              let okToPush = false
              if (devoir.debutAt) {
                dateDeb = new Date(devoir.debutAt.toString())
                if (dateDeb.getTime() >= this.$store.state.Exercices.periodeActive.debut.getTime() && dateDeb.getTime() <= this.$store.state.Exercices.periodeActive.fin.getTime()) {
                  okToPush = true
                }
              }
              if (devoir.finAt) {
                dateFin = new Date(devoir.finAt.toString())
                if (dateFin.getTime() >= this.$store.state.Exercices.periodeActive.debut.getTime() && dateFin.getTime() <= this.$store.state.Exercices.periodeActive.fin.getTime()) {
                  okToPush = true
                }
              }
              if (okToPush) {
                temp.push({ identifiant: devoir.identifiant, batch: devoir.batch, titre: devoir.titre, description: devoir.description, from: devoir.usager, debutAt: devoir.debutAt, finAt: devoir.finAt, exercices: [devoir], mark: devoir.resultat, status: devoir.statut, nombreUsagers: devoir.elementsUsagers.total })
              }
            } else if (!this.$store.state.Exercices.periodeActive) {
              temp.push({ identifiant: devoir.identifiant, batch: devoir.batch, titre: devoir.titre, description: devoir.description, from: devoir.usager, debutAt: devoir.debutAt, finAt: devoir.finAt, exercices: [devoir], mark: devoir.resultat, status: devoir.statut, nombreUsagers: devoir.elementsUsagers.total })
            }
          } else {
            if (this.$store.state.Exercices.periodeActive && (devoir.debutAt || devoir.finAt)) {
              let dateDeb = null
              let dateFin = null
              let okToPush = false
              if (devoir.debutAt) {
                dateDeb = new Date(devoir.debutAt.toString())
                if (dateDeb.getTime() >= this.$store.state.Exercices.periodeActive.debut.getTime() && dateDeb.getTime() <= this.$store.state.Exercices.periodeActive.fin.getTime()) {
                  okToPush = true
                }
              }
              if (devoir.finAt) {
                dateFin = new Date(devoir.finAt.toString())
                if (dateFin.getTime() >= this.$store.state.Exercices.periodeActive.debut.getTime() && dateFin.getTime() <= this.$store.state.Exercices.periodeActive.fin.getTime()) {
                  okToPush = true
                }
              }
              if (okToPush) {
                temp.find(e => e.identifiant === devoir.identifiant).exercices.push(devoir)
              }
            } else if (!this.$store.state.Exercices.periodeActive) {
              temp.find(e => e.identifiant === devoir.identifiant).exercices.push(devoir)
            }
          }
        })
        return temp
      } else {
        return []
      }
    }
  },
  methods: {
    quickFormatDevoir (devoir) {
      const listDevoir = []
      let donnateur = null
      let debutAt = null
      let finAt = null
      let titre = null
      let description = null
      let status = null
      const identifiant = devoir
      let batch = null
      let nombreUsagers = 0
      devoir.forEach((elem) => {
        titre = elem.titre
        batch = elem.batch
        description = elem.description
        if (nombreUsagers === 0) {
          nombreUsagers = elem.elementsUsagers.total
        }
        if (!status) {
          status = elem.statut
        }
        if (!donnateur) {
          donnateur = elem.usager
        }
        if (!debutAt && elem.debutAt) {
          debutAt = elem.debutAt
        }
        if (!finAt && elem.finAt) {
          finAt = elem.finAt
        }
        // meta contient pas mal de stock genre couleur et tout
        let type = elem.meta.includes('SuperNoeud') ? 'supernoeud' : 'graph'
        let devoirId = null
        const valeur = JSON.parse(elem.meta)
        if (valeur.version === 1) {
          if (valeur.data && valeur.data.id && valeur.data.id.includes('SuperNoeud')) {
            devoirId = valeur.data.id.split('gid://v1/Graphs::Graph::SuperNoeud/')[1]
          } else if (valeur.data && valeur.data.id && valeur.data.id.includes('Super/')) {
            devoirId = valeur.data.id.split('gid://v1/Graphs::Graph::Super/')[1]
          } else {
            console.error('la meta ne reference pas un devoir ', valeur)
          }
        } else {
          console.error('version de meta non géré', valeur)
        }
        // if (type === 'supernoeud') {
        //   devoirId = valeur.split('gid://v1/Graphs::Graph::SuperNoeud/')[1]
        // } else {
        //   devoirId = valeur.split('gid://v1/Graphs::Graph::Super/')[1]
        // }
        if (devoirId) {
          listDevoir.push({ type, devoir: devoirId, statut: elem.statut })
        }
      })
      return { identifiant, batch, titre, description, from: donnateur, debutAt, finAt, exercices: listDevoir, mark: null, status: status, nombreUsagers }
    }
  }
}
