<script>
import Status from '@/mixins/status.js'
import ChatControls from '@/mixins/chatControls.js'
import DevoirFormat from '@/mixins/devoirFormat.js'
import calendrierCalendrierElements from '@/mixins/calendrierCalendrierElements.js'
import DeleteEvent from '@/components/dialogs/calendrier/deleteEvent'
import MoyenneV2 from '@/components/statistiques/moyenneV2'
import TableauResize from '@/mixins/tableauResize.js'

export default {
  name: 'tableauDevoirs',
  mixins: [ChatControls, DevoirFormat, Status, TableauResize, calendrierCalendrierElements],
  components: {
    DeleteEvent,
    MoyenneV2
  },
  props: {
    hardcodded: { required: false, type: Boolean },
    search: { required: false, type: String, default: null },
    usagers: { required: false, type: Array, default: () => [] },
    salles: { required: false, type: Array, default: () => [] },
    extraContent: { required: false, type: Boolean, default: false },
    rounded: { required: false, type: Boolean, default: false },
    showExpired: { required: false, type: Boolean, default: false },
    maxShow: { required: false, type: Number, default: 10 },
    statutsFiltre: { required: false, type: Array, default: () => [] },
    searchDevoir: { required: false, type: String, default: null }
  },
  data () {
    return {
      expanded: [],
      singleExpand: true,
      nbTotal: 0,
      loadingDevoirs: true,
      devoirsActifs: null,
      fetchMore: false,
      page: 1,
      limit: 10,
      limitQ: 10,
      listDevoirs: null,
      lengthDev: 0,
      options: {
        page: 1,
        limit: 25,
        itemsPerPage: this.maxShow
      },
      headersResults: [
        {
          text: 'Titre',
          align: 'left',
          sortable: true,
          value: 'name',
          show: true
        },
        { text: 'Échéance', value: 'echeance', sortable: false, show: true },
        { text: 'État', value: 'status', sortable: false, show: true },
        { text: 'Élèves', value: 'usersQty', sortable: false, show: true },
        { text: 'Moyenne de groupe', value: 'moyenne', sortable: false, show: true },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false, show: true },
        { text: '', value: 'data-table-expand', show: this.extraContent }
      ]
    }
  },
  computed: {
    _headersResults () {
      // Certaines colonnes sont conditionnelles -> J'utilise cette computed pour les afficher / cacher
      return this.headersResults.filter(x => x.show)
    },
    sortResults () {
      let tableau = []
      if (this.devoirsActifs) {
        this.devoirsActifs.forEach((dev, index) => {
          let entry = {}
          entry.id = dev.id
          entry.data = dev
          entry.batch = dev.batch
          entry.identifiant = dev.identifiant
          entry.name = dev.titre
          entry.status = {
            color: this.parseStatusDevoirs(dev.statut).color,
            title: this.parseStatusDevoirs(dev.statut).name
          }
          entry.usersQty = dev.nbEleves !== undefined && Number.isFinite(dev.nbEleves) ? dev.nbEleves : dev.elementsUsagers.total
          entry.echeance = dev.finAt ? new Date(dev.finAt).toLocaleDateString(this.$i18n.locale + '-CA', { month: 'short', day: 'numeric', year: 'numeric' }) : '-'
          tableau.push(entry)
        })
      }
      return tableau
    }
  },
  watch: {
    options: {
      immediate: true,
      handler (newVal) {
        this.page = newVal.page
        this.fetchMore = false
        if (newVal.itemsPerPage !== -1) {
          this.limitQ = newVal.itemsPerPage
        } else {
          this.limitQ = 100
        }
      }
    },
    '$store.state.App.snackbarGlobalContent': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.description && newVal.description.includes(this.$t('messages.devoir-supprime-success'))) {
          this.$apollo.queries.listDevoirs.refetch()
        }
      }
    }
  },
  apollo: {
    listDevoirs: {
      query: require('@/graphql/queries/v2/calendrierCalendrierElements.gql'),
      variables () {
        const filtre = {}
        if (this.activePeriode) {
          filtre.debut = this.periodeActive.debut
          filtre.fin = this.periodeActive.fin
        }
        if (this.usagers.length > 0) {
          filtre.usagers = this.usagers
        }
        filtre.metas = [{ type: 'serie' }, { type: 'exercice' }, { type: 'boutiqueItem' }, { contenu: { type: 'ITEM' } }, { contenu: { type: 'ARBRE' } }]
        if (this.statutsFiltre?.length > 0) {
          filtre.statuts = this.statutsFiltre
        }
        return {
          tri: [{ sujet: 'CREATION', direction: 'DESC' }],
          page: this.page,
          identifiant: true,
          batch: true,
          filtre,
          q: this.searchDevoir,
          limit: this.limitQ
        }
      },
      update (data) {
        this.nbTotal = data.calendrierCalendrierElements.total
        this.$emit('nbDevoirs', this.nbTotal)
        this.loadingDevoirs = false
        if (data && data.calendrierCalendrierElements && data.calendrierCalendrierElements.resultat) {
          this.lengthDev = data.calendrierCalendrierElements.resultat.length
          if (!this.fetchMore) {
            this.devoirsActifs = data.calendrierCalendrierElements.resultat
            this.fixElevesCount()
          }
          if (this.page === 1 && this.limitQ === 100) {
            this.fetchMore = true
            this.fetchMoreDevoirs(this.page)
          }
          return data.calendrierCalendrierElements
        } else {
          return []
        }
      },
      watchLoading (isLoading) {
        this.loadingDevoirs = isLoading
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    removeEvent () {
      this.$store.dispatch('Exercices/fetchDevoirsRecent')
      this.$apollo.queries.listDevoirs.refetch()
    },
    async deleteDevoir (item) {
      this.$store.commit('Dialogs/setDeleteEvent', true)
      const elemEv = {
        name: item.name,
        identifiant: item.identifiant,
        data: item.data,
        type: 'devoir'
      }
      this.$store.commit('Dialogs/setElemToDelete', elemEv)
    },
    async fetchMoreDevoirs (page) {
      if (this.lengthDev < this.nbTotal) {
        let pageVal = page + 1
        await this.$apollo.queries.listDevoirs.fetchMore({
          variables: {
            page: pageVal
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newRes = fetchMoreResult.calendrierCalendrierElements.resultat
            this.lengthDev += newRes.length
            this.devoirsActifs.push(...newRes)
            return {
              listDevoirs: {
                __typename: previousResult.calendrierCalendrierElements.__typename,
                total: previousResult.calendrierCalendrierElements.total,
                resultat: [...previousResult.calendrierCalendrierElements.resultat, ...newRes]
              }
            }
          }
        })
        this.fetchMoreDevoirs(pageVal)
      }
    },
    customDevoirFilter (value, search, item) {
      return value != null && search != null && typeof value === 'string' && (value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)
    },
    fixElevesCount () {
      this.devoirsActifs.forEach((d, index) => {
        this.countElementsUsagersForCalendrierCalendrierElement(d.identifiant).then(nbEleves => {
          this.devoirsActifs.splice(index, 1, { ...d, nbEleves })
        })
      })
    }
  }
}
</script>

<template lang='pug'>
  .tableau-devoirs
    delete-event(@removeEvent='removeEvent')
    v-data-table.elevation-1(
      id='tableau'
      :headers='_headersResults'
      :items='sortResults'
      :items-per-page.sync='limit'
      :server-items-length='nbTotal'
      :options.sync='options'
      :single-expand='singleExpand'
      :show-expand='extraContent'
      :expanded.sync="expanded"
      item-key='id'
      :search='search'
      :custom-filter="customDevoirFilter"
      :no-data-text='$t("alerte.aucun-resultat")'
      :no-results-text='$t("alerte.aucun-result-recherche")'
      :mobile-breakpoint='0'
      light
      :class='{"light" : $store.state.Preferences.darkMode, "white" : !$store.state.Preferences.darkMode}'
      :style='{borderRadius: rounded ? "10px" : "0px"}',
      :hide-default-footer='nbTotal <= maxShow'
      :footer-props="{'items-per-page-text':$t('tableaux.devoir-per-page')}"
    )
      template(v-slot:item.name='{ item }')
        div.flex.items-center
          div(style='line-height:1.15')
            div {{item.name}}
      template(v-slot:item.moyenne='{ item }')
        div.flex.items-center
          div(style='line-height:1.15')
            moyenne-v2(:identifiant="item.identifiant")
      template(v-slot:item.status='{ item }')
        div.flex.items-center(v-if='tableauWidth && tableauWidth > 500')
          v-avatar.mr2(:color='item.status.color', size='6')
          span.secondary--text.text--lighten-3.f7 {{ item.status.title }}
        v-avatar(v-else, :color='item.status.color', size='10', v-tooltip.bottom="{ content: item.status.title, offset:'10px'}")
      template(v-slot:item.actions='{ item }')
        div.flex.items-center.justify-end
          v-menu(offset-y)
            template(v-slot:activator='{ on }')
              v-btn.mr1(icon,small, @click.prevent.stop='', v-on='on')
                font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
            v-card.pv2
              v-list(dense, nav)
                v-list-item(@click='deleteDevoir(item)')
                  font-awesome-icon.f6.mr2(:icon="['fad', 'trash-alt']")
                  v-list-item-content
                    v-list-item-title {{$t('action.supprimer')}}
          v-btn(v-if='item.identifiant', icon, small, :to="{ name: 'devoirDetail', params: { id: item.identifiant }, query: { identifiant: true } }")
            font-awesome-icon.f5(:icon="['far', 'arrow-right']")
          v-btn(v-else, icon, small, :to="{ name: 'devoirDetail', params: { id: item.batch }, query: { batch: true } }")
            font-awesome-icon.f5(:icon="['far', 'arrow-right']")
      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan="headers.length")
          div {{$t('messages.info-suppl')}}
            span.b {{item.name}}
            span &nbsp; {{$t('messages.a-venir')}}
</template>
<style lang='sass'>
  @import 'src/styles/pages/_groupeDetail'
  .tableau-devoirs
    // border-radius: $border-radius-root
    // overflow: hidden
    .v-data-table
      tbody
        tr
          td
            font-size: 12px !important
</style>
