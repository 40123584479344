<script>
import Chartist from 'chartist'
import * as ChartistTooltips from 'chartist-plugin-tooltips'
import FormatDate from '@/mixins/formatDate.js'
import ScoreFunctions from '@/mixins/scoreFunctions.js'

export default {
  name: 'ExerciceDetail',
  mixins: [FormatDate, ScoreFunctions],
  props: {
    exercices: { required: false, type: Object, default: () => {} },
    enfant: { required: false, type: Object, default: () => {} }
  },
  data () {
    return {
      matieres: 'Toutes les matières',
      categories: 'La classe',
      matiere: [],
      moyenne: [],
      // matiere: ['Toutes les matières', 'Français', 'Anglais', 'Mathématiques', 'Sciences', 'Histoire'],
      categorie: ['La province', "L'école", 'La classe'],
      chartData: {
        labels: [],
        series: []
      },
      // chartData: {
      //   labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      //   series: [
      //     [
      //       { meta: 'mathématique', value: 10 },
      //       { meta: 'mathématique', value: 50 },
      //       { meta: 'mathématique', value: 30 },
      //       { meta: 'mathématique', value: 90 },
      //       { meta: 'mathématique', value: 20 },
      //       { meta: 'mathématique', value: 100 }
      //     ],
      //     [
      //       { meta: 'français', value: 20 },
      //       { meta: 'français', value: 40 },
      //       { meta: 'français', value: 20 },
      //       { meta: 'français', value: 50 },
      //       { meta: 'français', value: 60 },
      //       { meta: 'français', value: 100 }
      //     ],
      //     [
      //       { meta: 'histoire', value: 10 },
      //       { meta: 'histoire', value: 20 },
      //       { meta: 'histoire', value: 30 },
      //       { meta: 'histoire', value: 40 },
      //       { meta: 'histoire', value: 50 },
      //       { meta: 'histoire', value: 100 }
      //     ]
      //   ]
      // },
      chartOptions: {
        axisY: {
          onlyInteger: true,
          high: 100,
          low: 0
        },
        axisX: {
          type: Chartist.FixedScaleAxis,
          divisor: 12,
          labelInterpolationFnc: (value, index) => {
            return new Date(2020, index).toLocaleString('fr-CA', { month: 'long' })
          }
        },
        // lineSmooth: true,
        lineSmooth: Chartist.Interpolation.simple({
          divisor: 12,
          fillHoles: false
        }),
        plugins: [
          Chartist.plugins.tooltip({
            tooltipFnc (meta, value) {
              return meta + ' </br> ' + value.split(',')[1] + '%'
            }
          })
        ]
      },
      responsiveOptions: [
        ['screen and (min-width: 641px) and (max-width: 1024px)', {
          axisX: {
            divisor: 6
          }
        }],
        ['screen and (max-width: 640px)', {
          axisX: {
            divisor: 4
          }
        }]
      ],
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: 'Matière',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: 'Moyenne (%)', value: 'moyenne', align: 'right' },
        { text: 'Moyenne du groupe (%)', value: 'moyenneGroupe', align: 'right' },
        { text: 'Indice ADR', value: 'indiceAdr', align: 'right' }

      ],
      headersResults: [
        { text: 'Date', value: 'date' },
        {
          text: 'Exercice',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        { text: 'Resultat (%)', value: 'result', align: 'right' },
        { text: 'Moyenne (%)', value: 'moyenne', align: 'right' },
        { text: 'Status', value: 'status', align: 'right' }
        // { text: 'Indice ADR', value: 'indiceAdr' }
      ]
    }
  },
  components: {
    ChartistTooltips
  },
  computed: {
    sortResults () {
      let tableau = []
      if (this.exercices) {
        Object.keys(this.exercices).forEach((exo, index) => {
          // console.log('exo', this.exercices[exo])
          this.exercices[exo].forEach(ex => {
            let entry = {}
            entry.id = ex.id
            entry.name = ex.graph.titre ? this.truncate(ex.graph.titre, 100) : 'N/D'
            entry.result = ex.mark ? Math.round(ex.mark) : 'N/D'
            entry.moyenne = ex.graph.moyenne ? Math.round(ex.graph.moyenne) : 'N/D'
            entry.status = ex.status ? this.getStatus(ex.status) : 'N/D'
            entry.date = ex.updated_at ? this.formatDate(ex.updated_at.split(' ')[0]) : 'N/D'
            tableau.push(entry)
          })
        })
      }
      return tableau
    }
  },
  watch: {
    exercices: {
      immediate: true,
      deep: true,
      handler (newVal) {
        this.calcMoyenne()
      }
    }
  },
  methods: {
    calcMoyenne () {
      let temp = []
      if (this.exercices && Object.keys(this.exercices).length > 0) {
        Object.keys(this.exercices).forEach((e) => {
          this.exercices[e].forEach((g) => {
            // FAUT CHECKER SI LA DATE EST DEJA PRESENTE
            const baseDate = new Date(g.updated_at.split(' ')[0])
            const dateExo = baseDate.getDate() + '/' + (baseDate.getMonth() + 1) + '/' + baseDate.getFullYear()
            // console.log(dateExo)
            if (!this.chartData.labels.find(e => e === dateExo)) {
              this.chartData.labels.push(dateExo)
            }
            if (g.graph.matiere_principales.length > 0) {
              g.graph.matiere_principales.forEach((m) => {
                const elem = temp.find(t => t.name === m.titre)
                if (!elem) {
                  // console.log('LE GRAPH', g)
                  // console.log('mat', m.titre)
                  this.matiere.push(m.titre)
                  this.chartData.series.push([])
                  this.chartData.series[this.chartData.series.length - 1].push({ meta: m.titre, y: g.mark ? Math.round(g.mark, 2) : 0, x: new Date(g.updated_at.split(' ')[0]) })
                  temp.push({
                    name: m.titre,
                    moyenne: g.mark ? g.mark : 0,
                    moyenneGroupe: g.graph.moyenne,
                    nbtot: 1,
                    indiceAdr: 'En cours'
                  })
                } else {
                  const elemSeries = this.chartData.series.findIndex(e => e.find(f => f.meta === m.titre))
                  this.chartData.series[elemSeries].push({ meta: m.titre, y: g.mark ? Math.round(g.mark, 2) : 0, x: new Date(g.updated_at.split(' ')[0]) })
                  elem.moyenne += g.mark ? g.mark : 0
                  elem.moyenneGroupe += g.graph.moyenne
                  elem.nbtot++
                }
              })
            } else {
              const elem = temp.find(t => t.name === 'Autres')
              if (elem) {
                const elemSeries = this.chartData.series.findIndex(e => e.find(f => f.meta === 'Autres'))
                this.chartData.series[elemSeries].push({ meta: 'Autres', y: g.mark ? Math.round(g.mark, 2) : 0, x: new Date(g.updated_at.split(' ')[0]) })
                elem.moyenne += g.mark ? g.mark : 0
                elem.moyenneGroupe += g.graph.moyenne
                elem.nbtot++
              } else {
                this.matiere.push('Autres')
                this.chartData.series.push([])
                this.chartData.series[this.chartData.series.length - 1].push({ meta: 'Autres', y: g.mark ? Math.round(g.mark, 2) : 0, x: new Date(g.updated_at.split(' ')[0]) })
                temp.push({
                  name: 'Autres',
                  moyenne: g.mark ? g.mark : 0,
                  moyenneGroupe: g.graph.moyenne,
                  nbtot: 1,
                  indiceAdr: 'En cours'
                })
              }
            }
          })
        })
        // console.log('matiere', this.matiere)
        // on fait ça pour faire des moyennes de valeurs et calculé indice adr
        temp.forEach((e) => {
          e.moyenne = Math.round(e.moyenne / e.nbtot, 2)
          e.moyenneGroupe = Math.round(e.moyenneGroupe / e.nbtot, 2)
          e.indiceAdr = e.moyenne >= 75 ? 'Rien à dire' : (e.moyenne >= 60 ? 'Sur la bonne voie' : 'À surveiller')
        })
      }
      // on va triè les graph par matières et on va prendre la moyenne de l'élève et la moyenne général puis faire un ratio entre les deux
      // EXEMPLE STRUCTURE
      // {
      //     name: 'Sciences',
      //     moyenne: 90,
      //     moyenneGroupe: 80,
      //     indiceAdr: 'À surveiller'
      //   }
      this.moyenne = temp
    },
    // COMMENTÉ RENVOIE TOUT LE TEMPS TRUE
    // getStyleFromResult (value) {
    //   if (parseInt(value) > 0) {
    //     return true
    //   } else {
    //     return true
    //   }
    // },
    getStatus (status) {
      if (status === 'in_process') {
        return 'En cours'
      } else if (status === 'finished') {
        return 'Terminé'
      } else {
        return 'Nouveau'
      }
    },
    truncate (text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    }
  }
}
</script>
<template lang="pug">
.resultats-index
  //- pre {{sortResults}}
  //- h1 je suis dans resultats
  div.resultats__wrapper.black--text
    template(v-if='exercices && Object.keys(exercices).length')
      div.chart-wrapper
        div.comparator__wrapper.padded-section
          div.mb3.f5 Comparer les résultats
          div.graph-controls__wrapper
            v-select(outlined, rounded, v-model='matieres', :items='matiere', label='Matières', hint='Choisissez une matière', hide-details, :dense='$store.state.App.mobileTemplate')
            span.pa3.f5 comparer avec
            v-select(outlined, rounded, v-model='categories', :items='categorie', label='Catégories', hint='Choisissez une catégorie', hide-details, :dense='$store.state.App.mobileTemplate')
        v-toolbar.mb4(text, color='dark', dark, flat)
          v-toolbar-title Vue d'ensemble
        chartist(ratio='ct-major-second', type='Line', :data='chartData', :options='chartOptions', :responsiveOptions='responsiveOptions')

      div.tableau-resultats__wrapper
        v-data-table.elevation-1(
          :headers='headers'
          :items='moyenne'
          :single-expand='singleExpand'
          :expanded.sync='expanded'
          item-key='name'
          show-expand
          :mobile-breakpoint='0'
          :no-data-text='"Aucun résultats à afficher"'
        )
          template(v-slot:top)
            v-toolbar(text, color='dark', dark, flat)
              v-toolbar-title Résultats par matières
          template(v-slot:item.moyenne='{ item }')
            div.flex.items-center.justify-end
              span.black--text {{ item.moyenne }}
              v-avatar.ml2(:color='getColorFromResult(item.moyenne).toString()', size='10')
          template(v-slot:item.moyenneGroupe='{ item }')
            div.flex.items-center.justify-end
              span.black--text {{ item.moyenneGroupe }}
              v-avatar.ml2(:color='getColorFromResult(item.moyenneGroupe).toString()', size='10')

      div.tableau-resultats__wrapper
        v-data-table.elevation-1(
          :headers='headersResults'
          :items='sortResults'
          :show-expand='false'
          :expanded.sync='expanded'
          item-key='id'
          :mobile-breakpoint='0'
          :no-data-text='"Aucun résultats à afficher"'
        )
          template(v-slot:top)
            v-toolbar(text, color='dark', dark, flat)
              v-toolbar-title Résultats par exercices
          template(v-slot:item.result='{ item }')
            div.flex.items-center.justify-end
              span.black--text {{ item.result }}
              v-avatar.ml2(:color='getColorFromResult(item.result).toString()', size='10')
          template(v-slot:item.moyenne='{ item }')
            div.flex.items-center.justify-end
              span.black--text {{ item.moyenne }}
              v-avatar.ml2(:color='getColorFromResult(item.moyenne).toString()', size='10')

    template(v-else)
      div.padded-section.f5.mt3 Aucun résultats enregistrés pour {{enfant.info.prenom}}
</template>
<style lang="sass">
@import 'src/styles/components/resultats/_resultats'

</style>
