<script>
// This component is similar to "moyenne.vue" but it uses "statistiqueCalendrierCalendrierElementsData"
// Instead of "moyenneCalendrierCalendrierElements" to get the data, via the mixin "CceStats"
import CceStats from '@/mixins/cceStats'
import displayNote from '@/mixins/displayNote'

export default {
  name: 'MoyenneV2',
  mixins: [CceStats, displayNote],
  props: {
    identifiant: { type: String, required: true },
    showLoading: { type: Boolean, required: false, default: true }
  },
  data: () => ({
    moyenne: null,
    loading: true
  }),
  mounted () {
    this.getMoyenne()
  },
  methods: {
    async getMoyenne () {
      this.loading = true
      const stats = await this.getCceStatsData(this.identifiant)
      this.moyenne = stats.avgNote
      this.loading = false
    }
  }
}
</script>

<template lang="pug">
.moyenne-v2
  v-progress-circular(v-if='loading' indeterminate)
  span(v-else-if="moyenne") {{ ratedNote(moyenne) }}
  span(v-else) -
</template>

<style lang="sass"></style>
