<script>
import ChatControls from '@/mixins/chatControls.js'
import ConnectEleves from '@/mixins/connectEleve.js'
import RetrieveIdentification from '@/mixins/retrieveIdentification.js'
import TableauDevoirs from '@/components/devoirs/tableauDevoirs.vue'
// import EnfantCard from '@/components/presentation/enfantCard.vue'
import StatsPreview from '@/components/statistiques/statsPreview.vue'
import Resultats from '@/components/enfants/resultats'
import DevoirPreview from '@/components/presentation/devoirPreview.vue'
import ProfilEdition from '@/components/dialogs/profile/profilEdition.vue'
import Moyenne from '@/components/statistiques/moyenne'
// import { groupBy } from 'lodash-es'
import NoteEnfant from '@/components/enfants/noteEnfant'

export default {
  name: 'enfantDetail',
  mixins: [ChatControls, RetrieveIdentification, ConnectEleves],
  components: {
    // EnfantCard
    Resultats,
    DevoirPreview,
    TableauDevoirs,
    ProfilEdition,
    Moyenne,
    StatsPreview,
    NoteEnfant
  },
  data () {
    return {
      classe: null,
      user: null,
      dokomaEx: null,
      skupQuery: true,
      showDialogLoading: false,
      showDialogError: false,
      relations: null,
      timeFilters: [
        { value: 0, titre: 'Tout' },
        { value: 1, titre: 'Cette semaine' },
        { value: 2, titre: 'Ce mois-ci' },
        { value: 3, titre: '6 derniers mois' },
        { value: 4, titre: 'Cette année' }
        // ON SE FAIT UNE ENTREE PAR MOIS ?
      ],
      selectedTimeFilter: null,
      loadingCalc: true,
      markGlobal: 0,
      listeExo: []
    }
  },
  computed: {
    showDeleteUser: {
      set (val) { this.$store.commit('Dialogs/setShowDeleteUser', val) },
      get () { return this.$store.state.Dialogs.showDeleteUser }
    },
    showAddGroup: {
      set (val) { this.$store.commit('Dialogs/setShowAddGroup', val) },
      get () { return this.$store.state.Dialogs.showAddGroup }
    },
    userToEdit: {
      get () { return this.$store.state.Dialogs.userToEdit },
      set (val) { this.$store.commit('Dialogs/setUserToEdit', val) }
    },
    showEleveEdition: {
      get () { return this.$store.state.Dialogs.showProfilEdition },
      set (val) { this.$store.commit('Dialogs/setProfilEdition', val) }
    },
    showAjoutEnfant: {
      set (val) { this.$store.commit('Dialogs/setAjoutEnfant', val) },
      get () { return this.$store.state.Dialogs.showAjoutEnfant }
    },
    getProfRelations () {
      let profs = []
      if (this.relations) {
        this.relations.forEach(element => {
          profs.push(element.superieur)
        })
      }
      return profs
    },
    ecoles () {
      let temp = []
      if (this.user && this.user.compagnies && this.user.compagnies.length > 0) {
        temp = this.user.compagnies
      }
      return temp
    }
  },
  apollo: {
    classe: {
      query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
      variables () {
        return {
          filtre: {
            usagers: [this.$route.params.id]
          }
        }
      },
      update (data) {
        return data.searchSalleClasses.resultat
      }
    },
    user: {
      query: require('@/graphql/queries/v2/salleClasseUsager.gql'),
      variables () {
        return { id: this.$route.params.id }
      },
      update (data) {
        return data.salleClasseUsager
      },
      fetchMoreResult: 'network-only',
      skip () { return !this.$route.params.id }
    },
    relations: {
      query: require('@/graphql/queries/v2/searchSalleClasseUsagerRelations.gql'),
      variables () {
        return {
          filtre: {
            superieur: true,
            usagers: [this.$route.params.id],
            natures: ['AUTORITE']
          }
        }
      },
      update (data) {
        return data.searchSalleClasseUsagerRelations.resultat
      }
    },
    // Vieille requet stats
    userStat: {
      query: require('@/graphql/queries/plus/userNote.gql'),
      client: 'plus',
      variables () {
        return {
          id: this.user.v1Id,
          statuts: ['FINISHED'],
          limit: 100
        }
      },
      update (data) {
        if (data.user) {
          this.calcMoyenne(data)
        }
        return data.user
      },
      skip () {
        return !this.user || (this.user && !this.user.v1Id)
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('Exercices/setPeriodeActive', null)
  },
  methods: {
    setUserToDelete (user) {
      this.showDeleteUser = true
      this.userToEdit = {
        id: user.id,
        name: user.info.prenom + ' ' + user.info.nom
      }
    },
    setUserToDeleteFromGroup (user, classe) {
      this.showDeleteUser = true
      this.userToEdit = {
        id: user.id,
        name: user.info.prenom + ' ' + user.info.nom,
        group: classe
      }
    },
    setGroupToAdd (user) {
      this.showAddGroup = true
      this.userToEdit = {
        id: user.id,
        name: user.info.prenom + ' ' + user.info.nom
      }
    },
    updateSelectedFilter (val) {
      const currentDate = new Date()
      if (val === 0 || !val) {
        this.$store.commit('Exercices/setPeriodeActive', null)
      } else if (val === 1) {
        // cette semaine
        let debut = currentDate.getDate() - currentDate.getDay()
        debut = new Date(currentDate.setDate(debut))
        const fin = new Date(currentDate.setDate(debut.getDate() + 6))
        const periode = { debut, fin, type: 1 }
        this.$store.commit('Exercices/setPeriodeActive', periode)
      } else if (val === 2) {
        // mois en cours
        const debut = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
        const fin = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        const periode = { debut, fin, type: 2 }
        this.$store.commit('Exercices/setPeriodeActive', periode)
      } else if (val === 3) {
        // 6 derniers mois
        const debut = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1)
        const fin = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        const periode = { debut, fin, type: 3 }
        this.$store.commit('Exercices/setPeriodeActive', periode)
      } else if (val === 4) {
        // annee
        const debut = new Date(currentDate.getFullYear() - 1, 0, 1)
        const fin = new Date(currentDate.getFullYear() - 1, 12, 0)
        const periode = { debut, fin, type: 4 }
        this.$store.commit('Exercices/setPeriodeActive', periode)
      }
    },
    // Vieille requete
    calcMoyenne (data) {
      // console.log('MOYENNE', data)
      this.loadingCalc = true
      let mark = 0
      this.listeExo = []
      // const nb_elem = data.user.exercice_graphs_classe_maison.length + data.user.exercice_graphs.length
      if (data.user.exercice_graphs_classe_maison.length > 0) {
        data.user.exercice_graphs_classe_maison.forEach((e) => {
          if (e.mark && !e.graph.tag_invisibles.find(t => t.name.inlucdes('Saglac' || t.name.inlucdes('Saguenay')))) {
            mark += e.mark
            this.listeExo.push(e)
          }
        })
      }
      if (data.user.exercice_graphs.length > 0) {
        data.user.exercice_graphs.forEach((e) => {
          if (e.mark) {
            mark += e.mark
            this.listeExo.push(e)
          }
        })
      }
      if (mark > 0) {
        this.markGlobal = Math.round(mark / this.listeExo.length, 2)
      } else {
        this.markGlobal = 0
      }
      this.loadingCalc = false
    }
  }
}
</script>

<template lang='pug'>
  .enfant-index.split-page(v-if='user')
    v-dialog(v-model='showDialogLoading', persistent, max-width='400')
      v-card(color='primary', dark)
        v-card-text
          div.dialog-text.pa4.white--text
            div.b.f4.mb3.tc Connexion en cours...
            v-progress-linear.mt3(indeterminate, color='light', height='10', rounded, striped)
    v-dialog(v-model='showDialogError', max-width='400')
      v-card(color='primary', dark)
        v-card-text
          div.dialog-text.pa4.white--text
            div.b.f4.mb3.tc Une erreur est survenue lors de la connexion
    //- v-dialog(v-model='showNameEdition', max-width='400')
    //-   v-card(color='primary', dark)
    //-     v-card-text
    //-       div.dialog-text.pa4.white--text
    //-         div.b.f4.mb3.tc Une erreur est survenue lors de la connexion
    //- eleve-edition(:user='user', @refetch='$apollo.queries.user.refetch()')
    profil-edition(:user='user', @refetch='$apollo.queries.user.refetch()', :eleve='true')
    .page-header(color='secondary')
      .header__inner-content
        v-btn.white--text.mb2(:small='$store.state.App.mobileTemplate', to='/eleves', rounded, color='secondary')
          font-awesome-icon.mr2.f4(:icon="['fas', 'arrow-left']")
          span Tous les élèves
        div.flex-grow-1
        div.mv3
          v-avatar.white.secondary--text(size='70', height='70', width='70')
            img(v-if='user.avatar && user.avatar.imageUrl', :src='user.avatar.imageUrl')
            div.f3.ttu(v-else-if='user.info && user.info.prenom && user.info.nom') {{user.info.prenom[0]}}.{{user.info.nom[0]}}.
            img(v-else, src='~@/assets/avatars/avatar_afro.png')
        .header-title(v-if='user && user.info')
          div.secondary-title Espace scolaire de
          div.main-title
            | {{user.info.prenom}} {{user.info.nom}}
            v-btn.ml2(icon, dark, @click='showEleveEdition = !showEleveEdition')
              font-awesome-icon.f4.o-50(:icon="['fad', 'pen']")
          div @{{user.info.username}}
    //- .page-actions
      v-card(color='primary', @click='', v-ripple, disabled)
        v-card-title Envoyer un message
      v-card(color='primary', @click='connectToEnfant(user)', v-ripple)
        v-card-title Accéder à la classe
      //- v-card(color='primary', @click='', v-ripple, disabled)
        v-card-title Ajouter à un groupe
      v-card(color='primary', @click='setUserToDelete(user)', v-ripple)
        v-card-title Retirer de mes élèves
      v-card(color='primary', v-ripple, @click='retrieveSingleIdentification(classe)')
        v-card-title Informations de connexion
    v-toolbar.filters-toolbar(color='dark', dark)
      div.flex.items-center
        v-select(v-model='selectedTimeFilter', :items='timeFilters', item-text='titre', item-value='value', placeholder='Filtrer par période', dense, hide-details, clearable, @change='updateSelectedFilter')
    div.page__main-content
      section.large-section
        .section-subtitle__header
          h2.section-subtitle Aperçu des résultats
        v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          template(v-if='user.v1Id')
            note-enfant(:user='user', :listExo='listeExo')
          template(v-else)
            div.pa2.black--text Fonctionnalité à venir
        .section-subtitle__header.mt3
          h2.section-subtitle Résultats par devoirs
        v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          resultats(:exercices='dokomaEx', :enfant='user')
      section.small-section
        .section-subtitle__header
          h2.section-subtitle Statistiques
        div
          stats-preview(:lineGraph='true', :salles='classe && classe.length > 0 ? classe.map(c => c.id) : []', :usagers='[user.id]')
            template(slot='numberStatsFirstRow')
              div.stat
                moyenne.stat-number(:salles='classe && classe.length > 0 ? classe.map(c => c.id).toString() : ""', :usagers='[user.id]', :decimals='0')
                div.stat-label Moyenne Générale
              div.stat
                span.stat-number() N/D
                div.stat-label Devoirs

        .section-subtitle__header.mt3
          h2.section-subtitle Établissement{{(ecoles && ecoles.length > 1) ? 's' : ''}} scolaire
        v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          ul(v-if='ecoles && ecoles.length')
            template(v-for='(ecole, index) in ecoles')
              li(:key='index') {{ecole.titre}}
              v-divider.mv2(v-if='index < ecoles.length - 1')
          div.padded-section.f5(v-else) Aucun établissement scolaire enregistré pour {{user.info.prenom}}

        .section-subtitle__header.mt3
          h2.section-subtitle Enseignant(e){{(getProfRelations && getProfRelations.length > 1) ? 's': ''}}
        v-sheet.section-card(light, :color='$store.state.Preferences.darkMode ? "light" : "white"')
          div.padded-section
            div(v-if='getProfRelations && getProfRelations.length')
              template(v-for='(prof, index) in getProfRelations')
                div.flex.items-center
                  v-avatar.accent.secondary--text.mr2(size='40', height='40', width='40')
                    img(v-if='prof.avatar && prof.avatar.imageUrl', :src='prof.avatar.imageUrl')
                    div.f5.ttu(v-else-if='prof.info && prof.info.prenom && prof.info.nom') {{prof.info.prenom[0]}}.{{prof.info.nom[0]}}.
                    img(v-else, src='~@/assets/avatars/avatar_afro.png')
                  div
                    div {{prof.info.prenom}} {{prof.info.nom}}
                    div.f7.i.o-50 @{{prof.info.username}}
                v-divider.mv2(v-if='index < getProfRelations.length - 1')
            div.f5(v-else) Aucun enseignant(e) enregistré pour {{user.info.prenom}}
</template>
<style lang='sass'>
  @import 'src/styles/pages/_enfantDetail'

</style>
