<script>
export default {
  name: 'Moyenne',
  props: {
    batch: { required: false, type: String, default: '' },
    salles: { required: false, type: String, default: '' },
    usagers: { required: false, type: Array, default: () => [] },
    value: { required: false, type: Boolean, default: false },
    decimals: { required: false, type: Number, default: 2 }
  },
  computed: {
    periodeActive () {
      return this.$store.state.Exercices.periodeActive
    }
  },
  data () {
    return {
      loading: true,
      moyenne: null
    }
  },
  apollo: {
    moyenne: {
      query: require('@/graphql/queries/v2/moyenneCalendrierCalendrierElements.gql'),
      variables () {
        const variables = {}
        if (this.batch) {
          variables.batches = [this.batch]
        }
        if (this.salles) {
          variables.salles = [this.salles]
        }
        if (this.usagers.length > 0) {
          variables.usagers = this.usagers
        }
        if (this.periodeActive) {
          // variables.debut = this.periodeActive.debut
          variables.fin = [this.periodeActive.debut, this.periodeActive.fin]
          if (this.periodeActive.type === 1) {
            // semaine
            variables.periode = 'JOUR'
          } else if (this.periodeActive.type === 2) {
            variables.periode = 'SEMAINE'
          } else {
            variables.periode = 'MOIS'
          }
        }
        if (!variables.periode) {
          variables.periode = 'MOIS'
        }
        return variables
      },
      update (data) {
        this.loading = false
        return data.moyenneCalendrierCalendrierElements.total
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  }
}
</script>

<template lang="pug">
  .moyenne
    v-progress-circular(v-if='loading', indeterminate)
    template(v-else-if='moyenne && !loading')
      //- v-progress-circular.result-donut(v-if='!value', :rotate='-90', :size='130', :width='25', :value='moyenne', color='accent') {{moyenne.toFixed(2)}}
      span() {{moyenne.toFixed(decimals)}}%
    template(v-else-if='!moyenne && !loading')
      //- v-progress-circular.result-donut(v-if='!value', :rotate='-90', :size='130', :width='25', :value='null', color='accent') N/D
      span() N/D
</template>

<style lang='sass'>
</style>
